<section class="document py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img src="../../assets/images/validate.jpg" class="img-fluid pb-5">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 m-auto">
                <div class="card  p-4 text-center">
                    <h3 class="text-center my-3">Validate Your ID</h3>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Enter Your ID">
                        </div>
                        <button type="button" class="btn btn-success" > Validate Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
