<section class="document py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img src="../../assets/images/contact-us.jpg" class="img-fluid pb-5">
            </div>
        </div>
    </div>
</section>
<section class="service-requst py-5" style="background-color: #ededed">
    <div class="container">
      <div class="row">
        <div class="col-md-10 m-auto">
          <h2 class="text-center pb-4">Contact Us</h2>
          <form class="clearfix" [formGroup]="contact">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-field-wrapper form-full">
                        <label><b>Enter Name</b></label>
                        <input
                          type="text"
                          class="input-sm form-full form-control"
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.name?.invalid &&
                              contact.controls?.name?.touched
                          }"
                          placeholder="Your Name"
                        />
                        <div
                          *ngIf="contact.controls?.name?.errors?.required"
                          class="invalid-feedback"
                        >
                          Name is required.
                        </div>
                      </div>
                </div>
                <div class="col-md-6">
                    <div class="form-field-wrapper">
                        <label><b>Enter Email</b></label>
                        <input
                          class="input-sm form-full form-control"
                          type="text"
                          formControlName="email"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.email?.invalid &&
                              contact.controls?.email?.touched
                          }"
                          placeholder="Email"
                        />
                        <div
                          *ngIf="contact.controls?.email?.errors?.required"
                          class="invalid-feedback"
                        >
                          Email is required.
                        </div>
                        <div
                          *ngIf="contact.controls?.email?.errors?.pattern"
                          class="invalid-feedback"
                        >
                          Enter valid Email.
                        </div>
                      </div>
                </div>
                <div class="col-md-6">
                    <div class="form-field-wrapper">
                        <label><b>Enter Mobile</b></label>
                        <input
                          type="text"
                          class="input-sm form-full form-control"
                          formControlName="phone"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.phone?.invalid &&
                              contact.controls?.phone?.touched
                          }"
                          placeholder="Phone"
                        />
                        <div
                          *ngIf="contact.controls?.phone?.errors?.required"
                          class="invalid-feedback"
                        >
                          Phone is required.
                        </div>
                        <div
                          *ngIf="contact.controls?.phone?.errors?.maxlength"
                          class="invalid-feedback"
                        >
                          Phone can maximum 10 Characters.
                        </div>
                        <div
                          *ngIf="contact.controls?.phone?.errors?.minlength"
                          class="invalid-feedback"
                        >
                          Phone will atleast 10 Characters.
                        </div>
                        <div
                          *ngIf="contact.controls?.phone?.errors?.pattern"
                          class="invalid-feedback"
                        >
                          Phone Will include only numbers.
                        </div>
                      </div>
                </div>
                <div class="col-md-12">
                    <div class="form-field-wrapper form-full">
                        <label><b>Message</b></label>
                        <textarea
                          class="form-full form-control"
                          rows="7"
                          formControlName="message"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.message?.invalid &&
                              contact.controls?.message?.touched
                          }"
                          placeholder="Your Message"
                        ></textarea>
                        <div
                          *ngIf="contact.controls?.message?.errors?.required"
                          class="invalid-feedback"
                        >
                          Message is required.
                        </div>
                      </div>
                      <recaptcha [size]="'invisible'" [hide]="false"> </recaptcha>
                </div>
                <div class="col-md-12">
                    <div class="form-field-wrapper mt-10">
                        <button
                          name="submit"
                          id="submit"
                          type="button"
                          class="btn-text btn btn-lg btn-success mt-3"
                          (click)="submit()"
                        >
                          Submit
                        </button>
                      </div>
                </div> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
