import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaService } from 'angular-recaptcha3';
import { ApiService } from '../service/api.service';
import { HelperService } from '../service/helper.service';
import "../../assets/js/smtp.js";
declare var Email: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contact: FormGroup;
  SECRET_KEY = '6LdEWVcbAAAAAIZUIipmudBsRhpY4Xftqoka61Zx';
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private recaptchaService: ReCaptchaService
  ) {
    this.contact = this.fb.group({
      name: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]),
      email: new FormControl(null , [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]),
      phone: new FormControl(null, [Validators.required ,Validators.maxLength(10), Validators.minLength(10) , Validators.pattern(/^[0-9]*$/)]),
      message: new FormControl(null , [Validators.required])
    })
  }

  ngOnInit(): void {
  }

  async submit(): Promise<void> {
    if (this.contact.valid) {
      this.recaptchaService.execute({action: `submit`}).then(token => {
        const payLoad = {
          secret: this.SECRET_KEY,
          recaptchav3_token: token
        }
        this.api.sendHttpCall(payLoad, `recaptcha` , 'post')?.subscribe( (res) => {
          if (res.data.success == true) {
            const payLoad = {
              name: this.contact.value.name,
              email: this.contact.value.email,
              phone: this.contact.value.phone,
              message: this.contact.value.message,
              site:"aryasamj"
            }
            Email.send({
              Host: "smtp.gmail.com",
              Username: "mimanshatech@gmail.com",
              Password: "Mim@nsha007",
              To: "live.hemant@gmail.com",
              From: "mimanshatech@gmail.com",
              Subject: this.contact.value.name,
              Body: `<p> Name: ${this.contact.value.name}  <br>
                 Phone No: ${this.contact.value.phone}  <br>
                 Email:  ${this.contact.value.email}  <br>
                 Site:  Aryasamj  <br>
                 Message: ${this.contact.value.message} </p>`,
            }).then( (message: any) => {
              this.api.sendHttpCall(payLoad , `aryasamj/enq`, 'post')?.subscribe( (resp: any) => {
                if (resp.status == true) {
                  this.helper.alertForSuccess(resp.message , 'Success!');
                  this.contact.reset();
                }
              });
            });
          }
        })
      });
    } else {
      Object.keys(this.contact.controls).forEach((field) => {
        const control = this.contact.get(field);
        control?.markAsTouched({ onlySelf: true });
        control?.markAsDirty({ onlySelf: true });
      });
    }
  }

}
