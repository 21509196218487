<section class="document py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img src="../../assets/images/document.jpg" class="img-fluid pb-5">

                <h3 class="text-center pb-4"><b>Marriage Registration Certificate</b></h3>
                <h5><b>GUIDELINES FOR ISSUANCE OF MARRIAGE REGISTRATION CERTIFICATE</b></h5>
                <h5><b>Eligibility Criteria</b></h5>
                <h6><b>The applicant is entitled for Marriage Registration Under Compulsory Registration Marriage order, 2014 if </b></h6>
                <ul class="document-list">
                    <li>One party either Groom or Bride must be a citizen of India.</li>
                    <li>The groom should be of 21 years of age and the bride of 18 years. (As on Marriage Date).</li>
                    <li>Marriage has been solemnized within the territorial jurisdiction of NCT of Delhi.</li>
                    <li>Registration under the Delhi Compulsory Registration of Marriage order 2014 shall be done within a period of 60 days, excluding the day on which final ceremony of marriage is solemnized.</li>
                    <li>Penalty shall be imposed if marriage is not registered within the prescribed period/ extended period.</li>
                </ul>
                <h4><b>The applicant is entitled for Marriage Registration Under the Hindu Marriage Act , 1955 if</b></h4>
                <ul class="document-list">
                    <li>One party either Groom or Bride must be a citizen of India.</li>
                    <li>Marriage has been solemnized within the territorial jurisdiction of NCT of Delhi.</li>
                    <li>Marriage has been solemnized between any two Hindus, Buddhists, Jains or Sikhs.</li>
                    <li>The groom should be of 21 years of age and the bride of 18 years. (As on Marriage Date).</li>
                    <li>Neither party has at the time of registration more than one spouse living.</li>
                    <li>Neither party</li>
                    <li>is incapable of giving a valid consent of it in consequence of unsoundness of mind; or</li>
                    <li>though capable of giving a valid consent has been suffering from mental disorder of such a kind or to such an extent as to be unfit for marriage and the procreation of children; or</li>
                    <li>has been subject to recurrent attacks of insanity or epilepsy;</li>
                    <li>The parties are not within the degrees of prohibited relationship unless the custom or usage governing each of them permits of a marriage between the two the parties are not “sapindas” of each other, unless the custom or usage governing each of them permits of a marriage between the two</li>
                    <li>The parties have been living together as husband and wife.</li>
                    <li>One party must have permanent residence within the district in Delhi of the Marriage Officer.</li>
                    <li>Both the parties (Bride and Groom) shall be present in person along with two witnesses bearing proof of permanent resident of Delhi who shall certify to the solemnization of such marriage on the day of appointment with Marriage officer.</li>
                    
                </ul>
                <h4><b>The applicant is entitled for Marriage Registration Under and Special Marriage Act , 1954 if </b></h4>
                <ul class="document-list">
                    <li>One party either Groom or Bride must be a citizen of India.</li>
                    <li>Marriage has been solemnized within the territorial jurisdiction of NCT of Delhi.</li>
                    <li>Marriage has been solemnized between any two persons of different religion.</li>
                    <li>The groom should be of 21 years of age and the bride of 18 years. (As on Marriage Date).</li>
                    <li>Neither party has at the time of registration more than one spouse living.</li>
                    <li>Neither party</li>
                    <li>is incapable of giving a valid consent of it in consequence of unsoundness of mind; or</li>
                    <li>though capable of giving a valid consent has been suffering from mental disorder of such a kind or to such an extent as to be unfit for marriage and the procreation of children; or</li>
                    <li>has been subject to recurrent attacks of insanity or epilepsy;</li>
                    <li>The parties are not within the degrees of prohibited relationship unless the custom or usage governing each of them permits of a marriage between the two</li>
                    <li>The parties have been living together as husband and wife.</li>
                    <li>The parties have been residing within the district in Delhi of the Marriage Officer for a period of not less than thirty days immediately preceding the date on which the application is made to him for registration of the marriage.</li>
                    <li>Both the parties (Bride and Groom) shall be present in person along with three witnesses bearing proof of permanent resident of Delhi who shall certify to the solemnization of such marriage on the day of appointment with Marriage officer.</li>
                    
                </ul>
                <h4><b>Identity Proof of Groom ( Any One is mandatory )</b></h4>
                <p>Aadhaar Card , PAN Card, Ration Card With Photo, Voter ID card,Passport,Driving License, Any Govt. recognized document</p>
                <h4><b>Identity Proof of Bride (Any One is mandatory )</b></h4>
                <p>Aadhaar Card , PAN Card, Ration Card With Photo, Voter ID card,Passport,Driving License, Any Govt. recognized document</p>
                <h4><b>Date of Birth Proof of Groom(Any One is mandatory )</b></h4>
                <ul class="document-list">
                    <li>Aadhaar Card</li>
                    <li>Verified DOB</li>
                    <li>Passport</li>
                    <li>Nursing home/Hospital Report/Vaccination card</li>
                    <li>Driving License</li>
                    <li>SSC from recognized board by GoI • CMO / Doctor Report</li>
                    <li>Certificate from School signed by Principal on • Birth Certificate /School Letter Head</li>
                
                </ul>
                <h4><b>Date of Birth Proof of Bride(Any One is mandatory )</b></h4>
                <ul class="document-list">
                    <li>Aadhaar Card</li>
                    <li>Verified DOB</li>
                    <li>Passport</li>
                    <li>Nursing home/Hospital Report/Vaccination card</li>
                    <li>Driving License</li>
                    <li>SSC from recognized board by GoI • CMO / Doctor Report</li>
                    <li>Certificate from School signed by Principal on • Birth Certificate /School Letter Head</li>
                
                </ul>
            </div>
        </div>
    </div>
</section>
