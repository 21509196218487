
<!-- about us -->
<section class="about_us">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img src="../../assets/images/about-content.jpg" class="img-fluid my-5">
                <p>
                    Arya Samaj was a Hindu modification advancement started in 1875 by Hindoo Dayananda Hindu deity. it's greatest proceeded in western and Northern piece of Bharat. The Arya Samaj conflicts with fondness for photos, animal retribution, action functions for forebears, molding rank regarding birth as opposition upon advantage, distance, shaver wedding, ventures, body claim to fame, and safe-haven commitments.
                    </p>
                <p>Arya Samaj has tried to work on refined steerage and intercaste wedding. it's created missions, asylums, and homes for widows; has begun a company of resources and schools and has in like manner accepted starvation lightening and clinical work. From its begin, Arya Samaj has been a major deem the occasion of Indian responsibility.</p>
                <p>Assembling its feelings with relevance the Vedas, Arya Samaj feelings area unit per the ensuing
                    It confides in three external existences, god, soul and nature. These three existences area unit clear in importance each other.
                    </p>
                <p>God is shapeless and does not take birth in any style. during this manner, Arya Samaj does not open up to image love and look of god.</p>
                <p>It acknowledges that the four Vedas do not seem to be depended upon any verbal professional for legitimateness as they are simple. It equally confides in Brahmanas trademark with the four Vedas, six elements of the Vedas, eleven Upanishads to the degree they are in simultaneity with the Vedas.</p>
                <p>It acknowledges that Dharma is that the factor that is simply, substantial, free of inclination and not conflicted with to the laws of God. Adharma is that the factor that is uneven and conflicted with to God's laws.</p>
                <p>Wedding in Arya SamajMandir is crucial and short, however affluent in services and spirited. the foremost day of your life is framed basic with North yankee country. Everything necessary could be a few to 3 hours for the wedding capacities to maneuver past and you get within the new live of life.</p>
                <p>
                    We arrange nearly everything for you as we are going to normally regard your time, but you are required to bring one or two of Garlands, Sweets, and Mangalsutra, and Sindoor. you are required to cause things with records and witnesses. we are going to normally reason you to meet all of the severe and legal traditions spherical an analogous time.
                </p>
            </div>
        </div>
    </div>
</section>
<!-- about us -->