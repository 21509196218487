 <!-- contact-us -->
 <div class="contact-us py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h6 class="font-weight-bold">Contact</h6>
          <p> 
            <a href="tel:093118 40764">09311840764</a>
              //
            <a href="tel:07827470426">07827470426</a>
          </p>
          <h6 class="font-weight-bold">Address</h6>
          <p>
            346, Block-E, Pocket-3,<br />
            Rohini Sec-18, New Delhi – 89
          </p>
          <h6 class="font-weight-bold">Hours</h6>
          <p>Mon - Sun : 12:00 PM - 12:00 PM / 12:00 AM - 12:00 AM</p>
        </div>
        <div class="col-md-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6996.623332807738!2d77.13497571847678!3d28.740110232504215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1623482510094!5m2!1sen!2sin"
            width="100%"
            height="250"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- contact-us -->
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <ul>
            <li>
              <a href="#"><i class="fa fa-facebook"></i> </a>
            </li>
            <li>
              <a href="#"><i class="fa fa-twitter"></i> </a>
            </li>
            <li>
              <a href="#"><i class="fa fa-google-plus"></i> </a>
            </li>
            <li>
              <a href="#"><i class="fa fa-instagram"></i> </a>
            </li>
            <li>
              <a href="#"><i class="fa fa-yelp"></i> </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>