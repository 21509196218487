
  <!-- banner section -->
  <div class="home-banner">
    <img
      src="../assets/images/banner.jpg"
      alt="ARYA SAMAJ VIVAH"
      class="img-fluid"
    />
  </div>
  <!-- banner section -->
  <!-- about us -->
  <div class="about-us py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="text-center">ARYA SAMAJ VIVAH</h1>
          <h6 class="text-center">( ARYA SAMAJ MARRIAGE VEDIC MANDAL TRUST )</h6>
        </div>
      </div>
      <div class="row py-5">
        <div class="col-md-8">
          <p>
            We, Arya Samaj Vivah, situated at Rohini, Delhi, realize that
            marriage, being one of the most important and sacred events in every
            culture, is not only the union of two individual souls but also of two
            families. We are the pioneer and prominent matrimonial services
            provider of efficient and reliable matrimonial services including
            match making, personal consultancy and other related allied services
            to individuals living in the country. Our company works diligently to
            create a rich and enormous database of high profile clients. Our teams
            are well versed in the dynamics of the marriage system of every
            community. We have dedicated teams that work meticulously in order to
            cater to the requirements of various categories of clients.
          </p>
        </div>
        <div class="col-md-4">
          <img src="../assets/images/about.jpg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
  <!-- about us -->
  <!-- services section -->
  <div class="our-servics">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-white">
          <h2 class="text-center pt-5">Our Services</h2>
          <h5 class="font-weight-bold">ARYA SAMAJ MANDIR MARRIAGE</h5>
          <p>
            Arya Samaj Marriage is appropriate amongst Hindus, Buddhists, Sikhs or
            Jains. A Hindu marriage can be solemnized between two Hindus (often
            when they are of different religion or nations) (Muslim or Christian)
            can convert their own religion into Hinduism and become Hindus in the
            Arya Samaj Mandir with their own free will and assent. Here at Arya
            Samaj Mandir marriage certificate is been provided to the newly
            married couple that is valid completely and not challengeable
            anywhere. Our part is to make it happen in a pure profession way. Love
            is the most divine feeling and the couples in love are blessed to be
            in. Love marriages earlier seemed to be curse for the couples but
            eventually with the time the contemporary era evolved in an improvised
            mindset of people and they started accepting love marriages. At Arya
            Samaj we make it happen in an easy, affordable, quick and valid way.
          </p>
          <h5 class="font-weight-bold">INTERCAST AND INTERRELIGION MARRIAGE</h5>
          <p>
            InterCaste and inter religion marriages in India are been prevailed
            for a very long time due to a very rigid caste system. Education tried
            its part to bring a change and somehow achieved but not fully altered
            the mindset of the people but here we make it possible for the blessed
            couples to get married in a valid manner. Because here caste and
            religion never hindered this pure bond and we transpire it.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- services section -->
  
  <!-- gallery section -->
  <section class="gallery">
    <!-- <a href="#" class="prev">&lt;</a>
      <a href="#" class="next">&gt;</a> -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="text-center pt-5">Photography</h2>
        </div>
        <div class="col-md-12">
          <div class="gallery-inr">
            <div><img src="../assets/images/gallery-1.jpg" alt="" /></div>
            <div class="vertical">
              <img src="../assets/images/gallery-2.jpg" alt="" />
            </div>
            <div class="horizontal">
              <img src="../assets/images/gallery-3.jpg" alt="" />
            </div>
            <div><img src="../assets/images/gallery-4.jpg" alt="" /></div>
            <div><img src="../assets/images/gallery-5.jpg" alt="" /></div>
            <!-- <div class="big">
              <img src="../assets/images/gallery-6.jpg" alt="" />
            </div> -->
            <div><img src="../assets/images/gallery-7.jpg" alt="" /></div>
            <!-- <div class="vertical">
              <img src="../assets/images/gallery-8.jpg" alt="" />
            </div> -->
            <!-- <div><img src="../assets/images/gallery-9.jpg" alt="" /></div>
            <div class="horizontal">
              <img src="../assets/images/gallery-10.jpg" alt="" />
            </div>
            <div><img src="../assets/images/gallery-11.jpg" alt="" /></div>
            <div class="big">
              <img src="../assets/images/gallery-12.jpg" alt="" />
            </div>
            <div><img src="../assets/images/gallery-13.jpg" alt="" /></div>
            <div class="horizontal">
              <img src="../assets/images/gallery-14.jpg" alt="" />
            </div>
            <div><img src="../assets/images/gallery-15.jpg" alt="" /></div>
            <div class="big">
              <img src="../assets/images/gallery-16.jpg" alt="" />
            </div>
            <div><img src="../assets/images/gallery-17.jpg" alt="" /></div>
            <div class="vertical">
              <img src="../assets/images/gallery-18.jpg" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- gallery section -->

  <!-- service request -->
  <section class="service-requst py-5" style="background-color: #ededed">
    <div class="container">
      <div class="row">
        <div class="col-md-10 m-auto">
          <h2 class="text-center pb-4">Contact Us</h2>
          <form class="clearfix" [formGroup]="contact">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-field-wrapper form-full">
                        <label><b>Enter Name</b></label>
                        <input
                          type="text"
                          class="input-sm form-full form-control form-control-lg"
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.name?.invalid &&
                              contact.controls?.name?.touched
                          }"
                          placeholder="Your Name"
                        />
                        <div
                          *ngIf="contact.controls?.name?.errors?.required"
                          class="invalid-feedback"
                        >
                          Name is required.
                        </div>
                      </div>
                </div>
                <div class="col-md-6">
                    <div class="form-field-wrapper">
                        <label><b>Enter Email</b></label>
                        <input
                          class="input-sm form-full form-control form-control-lg"
                          type="text"
                          formControlName="email"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.email?.invalid &&
                              contact.controls?.email?.touched
                          }"
                          placeholder="Email"
                        />
                        <div
                          *ngIf="contact.controls?.email?.errors?.required"
                          class="invalid-feedback"
                        >
                          Email is required.
                        </div>
                        <div
                          *ngIf="contact.controls?.email?.errors?.pattern"
                          class="invalid-feedback"
                        >
                          Enter valid Email.
                        </div>
                      </div>
                </div>
                <div class="col-md-6">
                    <div class="form-field-wrapper">
                        <label><b>Enter Mobile</b></label>
                        <input
                          type="text"
                          class="input-sm form-full form-control form-control-lg"
                          formControlName="phone"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.phone?.invalid &&
                              contact.controls?.phone?.touched
                          }"
                          placeholder="Phone"
                        />
                        <div
                          *ngIf="contact.controls?.phone?.errors?.required"
                          class="invalid-feedback"
                        >
                          Phone is required.
                        </div>
                        <div
                          *ngIf="contact.controls?.phone?.errors?.maxlength"
                          class="invalid-feedback"
                        >
                          Phone can maximum 10 Characters.
                        </div>
                        <div
                          *ngIf="contact.controls?.phone?.errors?.minlength"
                          class="invalid-feedback"
                        >
                          Phone will atleast 10 Characters.
                        </div>
                        <div
                          *ngIf="contact.controls?.phone?.errors?.pattern"
                          class="invalid-feedback"
                        >
                          Phone Will include only numbers.
                        </div>
                      </div>
                </div>
                <div class="col-md-12">
                    <div class="form-field-wrapper form-full">
                        <label><b>Message</b></label>
                        <textarea
                          class="form-full form-control"
                          rows="7"
                          formControlName="message"
                          [ngClass]="{
                            'is-invalid':
                              contact.controls?.message?.invalid &&
                              contact.controls?.message?.touched
                          }"
                          placeholder="Your Message"
                        ></textarea>
                        <div
                          *ngIf="contact.controls?.message?.errors?.required"
                          class="invalid-feedback"
                        >
                          Message is required.
                        </div>
                      </div>
                      <recaptcha [size]="'invisible'" [hide]="false"> </recaptcha>
                </div>
                <div class="col-md-12">
                    <div class="form-field-wrapper mt-10">
                        <button
                          name="submit"
                          id="submit"
                          type="button"
                          class="btn-text btn btn-lg btn-success mt-3"
                          (click)="submit()"
                        >
                          Submit
                        </button>
                      </div>
                </div> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- service request -->
 