<!-- site top nav -->
<div class="top-nav">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light bg-light bg-transparent">
            <a class="navbar-brand" href="#"
              ><img src="../assets/images/logo.png" alt=""
            /></a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
  
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a class="nav-link" href="#">Home </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="[ '/about' ]" routerLinkActive="active" >About</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="[ '/document' ]" routerLinkActive="active" >Documents Required</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="[ '/contact' ]" routerLinkActive="active">Contact</a>
                </li>
                <li  class="nav-item"><a href="tel:+917827470426" class="nav-link">+917827470426</a></li> 
                  <li  class="nav-item"><a href="tel:+9193118 40764" class="nav-link">+9193118 40764</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
</div>
<!-- site top nav -->