import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './service/api.service';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DocumentComponent } from './document/document.component';
import { ValidateComponent } from './validate/validate.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [AppComponent, AboutComponent, HomeComponent, HeaderComponent, FooterComponent, DocumentComponent, ValidateComponent, ContactComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // NgxSpinnerModule,
    ToastrModule.forRoot(),
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: '6LdEWVcbAAAAAAMfqkSmnI13qSEJ-RIP7CK-1O3z',
      },
      normal: {
        sitekey: '6LdEWVcbAAAAAAMfqkSmnI13qSEJ-RIP7CK-1O3z',
      },
      language: 'en',
    }),
  ],
  providers: [ApiService],
  bootstrap: [AppComponent],
})
export class AppModule {}
